//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
header {
  padding-top: 21px;
  padding-bottom: 26px;

  background-color: $semi-black;

  .header-content,
  .navbar-logo {
    display: flex;
    align-items: center;
  }

  .header-content {
    justify-content: space-between;
    padding: 0 15px;
  }

  .action-holder {
    width: 50%;
  }

  .logo {
    width: 90px;
    max-width: unset;
  }

  .t-logo {
    margin-left: 9px;

    font-family: $font-roboto-slab;
    font-size: 17px;
    letter-spacing: 0px;
    color: $white;
  }
}

@include respond-to(sm-down) {
  header .t-logo {
    font-size: 13px;
  }
}