//main: ../main.scss

/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: normal 125% / 1.4 $text-font-stack;
}

.t {
  font-family: $font-roboto;
  font-size: 17px;
  color: $semi-black;
}

.t-title {
  @extend .t;

  font-weight: 600;
}

.t-text {
  @extend .t;

  font-weight: normal;
}